import { TemplateElement } from '../../01-base/TemplateElement';
import style from './ScrollToTopElement.css';

class ScrollToTopElement extends TemplateElement {
    constructor() {
        super(null, { autoRender: true, styles: [style] });
    }

    // prettier-ignore
    template() {
        return `
            <div class="back-to-top global-styles" onclick=""></div>
        `;
    }

    properties() {
        return {
            duration: 1000,
        };
    }

    events() {
        return {
            this: {
                click: () => {
                    this.scrollToTop();
                },
            },
        };
    }

    scrollToTop() {
        const htmlScroll = window
            .getComputedStyle(document.querySelectorAll('html')[0], null)
            .getPropertyValue('scroll-behavior');

        if (htmlScroll === 'smooth') {
            window.scrollTo(0, 0);
        } else {
            const duration = this.duration;
            const difference = document.documentElement.scrollTop || document.body.scrollTop;
            const startTime = performance.now();

            // noinspection JSAnnotator
            function step(timeStamp) {
                let normalizedTime = (timeStamp - startTime) / duration;
                if (normalizedTime > 1) normalizedTime = 1;

                window.scrollTo(0, difference * Math.cos(normalizedTime * Math.PI));
                if (normalizedTime < 1) window.requestAnimationFrame(step);
            }
            window.requestAnimationFrame(step);
        }
    }
}

customElements.define('scroll-to-top', ScrollToTopElement);
